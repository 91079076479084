:root {
  /* Break Points */
  --bp-sm: 576px;
  --bp-md: 768px;
  --bp-lg: 992px;
  --bp-xl: 1200px;
  --bp-xxl: 1400px;

  /* Color Palette */
  --sap-green: #447127;
  --sap-green-filter: invert(41%) sepia(10%) saturate(2989%) hue-rotate(53deg)
    brightness(85%) contrast(82%);
  --middle-green: #61874c;
  --middle-green-filter: invert(45%) sepia(48%) saturate(378%) hue-rotate(55deg)
    brightness(97%) contrast(86%);
  --spanish-green: #1d8e4e;
  --spanish-green-filter: invert(37%) sepia(100%) saturate(333%)
    hue-rotate(93deg) brightness(99%) contrast(95%);
  --timberwolf: #d5d8cc;
  --timberwolf-filter: invert(91%) sepia(7%) saturate(256%) hue-rotate(34deg)
    brightness(96%) contrast(87%);
  --grullo: #b29a83;
  --grullo-filter: invert(71%) sepia(19%) saturate(449%) hue-rotate(348deg)
    brightness(87%) contrast(81%);
  --jet: #2d2d2d;
  --jet-filter: invert(13%) sepia(9%) saturate(10%) hue-rotate(332deg)
    brightness(93%) contrast(86%);
  --maximum-red: #d82b26;
  --maximum-red-filter: invert(25%) sepia(93%) saturate(1604%)
    hue-rotate(339deg) brightness(91%) contrast(106%);

  /* Dark Theme */
  --color-primary: var(--middle-green);
  --filter-primary: var(--middle-green-filter);

  --color-secondary: var(--grullo);
  --filter-secondary: var(--grullo-filter);

  --color-dark: var(--jet);
  --filter-dark: var(--jet-filter);

  --color-light: var(--timberwolf);
  --filter-light: var(--timberwolf-filter);

  --color-accent: var(--spanish-green);
  --filter-accent: var(--spanish-green-filter);

  --color-background: var(--color-dark);
  --color-text: var(--color-light);
  --color-border: var(--color-secondary);
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

header {
    height: 100vh;
    min-height: 640px;
    max-height: 1080px;
}

header div {
    padding: auto 0px;
}

header h1 {
  color: var(--color-secondary);
  filter: drop-shadow(1px 1px 2px var(--jet));
}

header img {
    max-width: 350px;
    /* filter: var(--filter-primary); */
    filter: drop-shadow(1px 1px 1px var(--jet));
}

.spanREPG {
  font-weight: bold;
}
.spanGreenTech {
  font-weight: bold;
  color: var(--color-primary);
}
.spanGREIT {
  font-weight: bold;
  color: var(--color-secondary);
}

.vr {
  color: var(--color-secondary);
}

.bg-img {
  z-index: -1;
}

#indexBgImg {
    /* Photo by Clay LeConey on Unsplash */
    content: url("../resource/img/background-sm.jpg");
}

/* sm */
/* @media screen and (min-width: 576px) { */
/*   #indexBgImg { */
/*     /\* Photo by Clay LeConey on Unsplash *\/ */
/*     content: url("../resource/img/background-sm.jpg"); */
/*   } */
/*   #aboutBgImg { */
/*     /\* Photo by LYCS Architecture on Unsplash *\/ */
/*     content: url("../resource/img/background-sm-about.jpg"); */
/*   } */
/*   #servicesBgImg { */
/*     /\* Photo by Marek Minor on Unsplash *\/ */
/*     content: url("../resource/img/background-sm-services.jpg"); */
/*   } */
/*   #technologyBgImg { */
/*     /\* Photo by Fredrika Carlsson on Unsplash *\/ */
/*     content: url("../resource/img/background-sm-technology.jpg"); */
/*   } */
/*   #procurementBgImg { */
/*     /\* Photo by John Calma on Unsplash *\/ */
/*     content: url("../resource/img/background-sm-procurement.jpg"); */
/*   } */
/* } */
/* md */
@media screen and (min-width: 768px) {
  #indexBgImg {
    /* Photo by Clay LeConey on Unsplash */
    content: url("../resource/img/background-md.jpg");
  }
  #aboutBgImg {
    /* Photo by LYCS Architecture on Unsplash */
    content: url("../resource/img/background-md-about.jpg");
  }
  #servicesBgImg {
    /* Photo by Marek Minor on Unsplash */
    content: url("../resource/img/background-md-services.jpg");
  }
  #technologyBgImg {
    /* Photo by Fredrika Carlsson on Unsplash */
    content: url("../resource/img/background-md-technology.jpg");
  }
  #procurementBgImg {
    /* Photo by John Calma on Unsplash */
    content: url("../resource/img/background-md-procurement.jpg");
  }
}
/* lg */
@media screen and (min-width: 992px) {
  #indexBgImg {
    /* Photo by Clay LeConey on Unsplash */
    content: url("../resource/img/background-lg.jpg");
  }
  #aboutBgImg {
    /* Photo by LYCS Architecture on Unsplash */
    content: url("../resource/img/background-lg-about.jpg");
  }
  #servicesBgImg {
    /* Photo by Marek Minor on Unsplash */
    content: url("../resource/img/background-lg-services.jpg");
  }
  #technologyBgImg {
    /* Photo by Fredrika Carlsson on Unsplash */
    content: url("../resource/img/background-lg-technology.jpg");
  }
  #procurementBgImg {
    /* Photo by John Calma on Unsplash */
    content: url("../resource/img/background-lg-procurement.jpg");
  }
}
/* xl */
@media screen and (min-width: 1200px) {
  #indexBgImg {
    /* Photo by Clay LeConey on Unsplash */
    content: url("../resource/img/background-xl.jpg");
  }
  #aboutBgImg {
    /* Photo by LYCS Architecture on Unsplash */
    content: url("../resource/img/background-xl-about.jpg");
  }
  #servicesBgImg {
    /* Photo by Marek Minor on Unsplash */
    content: url("../resource/img/background-xl-services.jpg");
  }
  #technologyBgImg {
    /* Photo by Fredrika Carlsson on Unsplash */
    content: url("../resource/img/background-xl-technology.jpg");
  }
  #procurementBgImg {
    /* Photo by John Calma on Unsplash */
    content: url("../resource/img/background-xl-procurement.jpg");
  }
}
/* xxl */
@media screen and (min-width: 1400px) {
  #indexBgImg {
    /* Photo by Clay LeConey on Unsplash */
    content: url("../resource/img/background-xxl.jpg");
  }
  #aboutBgImg {
    /* Photo by LYCS Architecture on Unsplash */
    content: url("../resource/img/background-xxl-about.jpg");
  }
  #servicesBgImg {
    /* Photo by Marek Minor on Unsplash */
    content: url("../resource/img/background-xxl-services.jpg");
  }
  #technologyBgImg {
    /* Photo by Fredrika Carlsson on Unsplash */
    content: url("../resource/img/background-xxl-technology.jpg");
  }
  #procurementBgImg {
    /* Photo by John Calma on Unsplash */
    content: url("../resource/img/background-xxl-procurement.jpg");
  }
}

nav {
  transition: background-color 0.25s;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler-icon {
  filter: var(--filter-secondary);
  background-image: url("../resource/icon/bars-solid.svg");
}
.navbar-toggler-icon:hover {
  filter: var(--filter-primary);
}

.nav-link {
  position: relative;
  color: var(--color-secondary);
}
.nav-link::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background-color: var(--color-secondary);
  transform-origin: bottom right;
  transition: transform 0.25s ease;
  transform: scaleX(0);
}
.active,
.nav-link:hover {
  color: var(--color-primary);
}
.nav-link:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}
.show {
  background-color: var(--color-background);
}

#enquireBtn {
  background-color: var(--color-primary);
  color: var(--color-text);
  box-shadow: 1px 1px 2px var(--color-dark);
}
#enquireBtn:hover {
  background-color: var(--color-secondary);
  color: var(--color-dark);
}

main,
footer {
  max-width: 1920px;
  background-color: var(--color-background);
}

section {
  max-width: 1440px;
}

.content-section {
  background-color: var(--color-background);
  border: 2px solid var(--color-border);
}
.content-section h2 {
  color: var(--color-secondary);
}
/* .content-section p, */
/* .content-section li { */
/*   text-align: justify; */
/* } */

.content-section img {
  width: 100%;
}

#about img {
  /* Photo by krakenimages on Unsplash */
  content: url("../resource/img/about-1322x851.jpg");
}
#services img {
  /* Photo by Jacek Dylag on Unsplash */
  content: url("../resource/img/services-1322x851.jpg");
}
#technology img {
  /* Photo by David Cristian on Unsplash */
  content: url("../resource/img/technology-1322x851.jpg");
}
#procurement img {
  /* Photo by Sora Shimazaki from Pexels */
  content: url("../resource/img/procurement-1322x851.jpg");
}
/* sm */
/* @media screen and (min-width: 576px  ) { */
/* @media screen and (min-width: 768px  ) { */
/* @media screen and (min-width: 992px) { */
/* @media screen and (min-width: 1200px ) { */
/* @media screen and (min-width: 1400px ) { */

@media screen and (min-width: 576px) {
    .content-section p,
    .content-section li {
        text-align: justify;
    }
}

/* lg */
@media screen and (min-width: 992px) {
  #about img {
    /* Photo by krakenimages on Unsplash */
    content: url("../resource/img/about-480x720.jpg");
  }
  #services img {
    /* Photo by Jacek Dylag on Unsplash */
    content: url("../resource/img/services-480x720.jpg");
  }
  #technology img {
    /* Photo by David Cristian on Unsplash */
    content: url("../resource/img/technology-480x720.jpg");
  }
  #procurement img {
    /* Photo by Sora Shimazaki from Pexels */
    content: url("../resource/img/procurement-480x720.jpg");
  }
}

footer a {
  text-decoration: none;
}

.section-btn > a,
#formSubmitBtn {
  background-color: var(--color-primary);
  color: var(--color-text);
}

.section-btn > a:hover,
#formSubmitBtn:hover {
  background-color: var(--color-secondary);
  color: var(--color-dark);
}

#contact {
  color: var(--color-text);
}

#contact h2 {
  color: var(--color-secondary);
}
#contact h4 {
  color: var(--color-primary);
}

#contact img {
  width: 2rem;
  filter: var(--filter-secondary);
}

#contact a {
  color: var(--color-secondary);
}
#contact a:hover {
  color: var(--color-primary);
}
#email a {
  color: var(--color-text);
}

#contact form label,
#contact form input {
  background: none;
  border: none;
  color: var(--color-secondary);
  caret-color: var(--color-secondary);
}
#contact form input {
  color: var(--color-primary);
}

#contact form select {
  color: var(--color-secondary);
  background-color: var(--color-background);
  border-color: var(--color-border);
}
#contact form select:hover {
  background-color: var(--color-secondary);
  color: var(--color-dark);
}

.form-border {
  border-bottom: 1px solid var(--color-border);
}

#copyright {
  color: var(--color-text);
}

#pageNavBtn {
  position: sticky !important;
  top: 1rem;
}

#pageNavBtn a {
  filter: var(--filter-secondary);
  text-decoration: none;
  padding: 0.5rem 0;
  margin: 0;
}
#pageNavBtn a:hover {
  filter: var(--filter-primary);
}

#pageNavBtn img {
  width: 100%;
  height: 1.5rem;
}

.page-section {
  /* text-align: justify; */
  background-color: var(--color-background);
  /* border: 1px solid var(--color-border); */
}

/* sm */
/* @media screen and (min-width: 576px  ) { */
/* @media screen and (min-width: 768px  ) { */
/* @media screen and (min-width: 992px) { */
/* @media screen and (min-width: 1200px ) { */
/* @media screen and (min-width: 1400px ) { */

/* sm */
@media screen and (min-width: 576px) {
    .page-section {
        text-align: justify;
    }
}

/* md */
@media screen and (min-width: 768px) {
  .page-section {
    width: 80%;
    border: 1px solid var(--color-border);
  }
}
/* /\* lg *\/ */
/* @media screen and (min-width: 992px) { */
/*   .page-section { */
/*     width: 80%; */
/*   } */
/* } */

.page-section h1 {
  color: var(--color-primary);
}
