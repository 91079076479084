:root {
  --bp-sm: 576px;
  --bp-md: 768px;
  --bp-lg: 992px;
  --bp-xl: 1200px;
  --bp-xxl: 1400px;
  --sap-green: #447127;
  --sap-green-filter: invert(41%) sepia(10%) saturate(2989%) hue-rotate(53deg) brightness(85%) contrast(82%);
  --middle-green: #61874c;
  --middle-green-filter: invert(45%) sepia(48%) saturate(378%) hue-rotate(55deg) brightness(97%) contrast(86%);
  --spanish-green: #1d8e4e;
  --spanish-green-filter: invert(37%) sepia(100%) saturate(333%) hue-rotate(93deg) brightness(99%) contrast(95%);
  --timberwolf: #d5d8cc;
  --timberwolf-filter: invert(91%) sepia(7%) saturate(256%) hue-rotate(34deg) brightness(96%) contrast(87%);
  --grullo: #b29a83;
  --grullo-filter: invert(71%) sepia(19%) saturate(449%) hue-rotate(348deg) brightness(87%) contrast(81%);
  --jet: #2d2d2d;
  --jet-filter: invert(13%) sepia(9%) saturate(10%) hue-rotate(332deg) brightness(93%) contrast(86%);
  --maximum-red: #d82b26;
  --maximum-red-filter: invert(25%) sepia(93%) saturate(1604%) hue-rotate(339deg) brightness(91%) contrast(106%);
  --color-primary: var(--middle-green);
  --filter-primary: var(--middle-green-filter);
  --color-secondary: var(--grullo);
  --filter-secondary: var(--grullo-filter);
  --color-dark: var(--jet);
  --filter-dark: var(--jet-filter);
  --color-light: var(--timberwolf);
  --filter-light: var(--timberwolf-filter);
  --color-accent: var(--spanish-green);
  --filter-accent: var(--spanish-green-filter);
  --color-background: var(--color-dark);
  --color-text: var(--color-light);
  --color-border: var(--color-secondary);
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}

header {
  height: 100vh;
  min-height: 640px;
  max-height: 1080px;
}

header div {
  padding: auto 0;
}

header h1 {
  color: var(--color-secondary);
  filter: drop-shadow(1px 1px 2px var(--jet) );
}

header img {
  max-width: 350px;
  filter: drop-shadow(1px 1px 1px var(--jet) );
}

.spanREPG {
  font-weight: bold;
}

.spanGreenTech {
  color: var(--color-primary);
  font-weight: bold;
}

.spanGREIT {
  color: var(--color-secondary);
  font-weight: bold;
}

.vr {
  color: var(--color-secondary);
}

.bg-img {
  z-index: -1;
}

#indexBgImg {
  content: url("background-sm.f640f294.jpg");
}

@media screen and (min-width: 768px) {
  #indexBgImg {
    content: url("background-md.7bc08435.jpg");
  }

  #aboutBgImg {
    content: url("background-md-about.05100228.jpg");
  }

  #servicesBgImg {
    content: url("background-md-services.c326cab0.jpg");
  }

  #technologyBgImg {
    content: url("background-md-technology.c0db5bf0.jpg");
  }

  #procurementBgImg {
    content: url("background-md-procurement.95a0a9c8.jpg");
  }
}

@media screen and (min-width: 992px) {
  #indexBgImg {
    content: url("background-lg.38501d47.jpg");
  }

  #aboutBgImg {
    content: url("background-lg-about.d7e1424c.jpg");
  }

  #servicesBgImg {
    content: url("background-lg-services.4a584a53.jpg");
  }

  #technologyBgImg {
    content: url("background-lg-technology.c0c2515e.jpg");
  }

  #procurementBgImg {
    content: url("background-lg-procurement.8a33080c.jpg");
  }
}

@media screen and (min-width: 1200px) {
  #indexBgImg {
    content: url("background-xl.43736991.jpg");
  }

  #aboutBgImg {
    content: url("background-xl-about.7cb46624.jpg");
  }

  #servicesBgImg {
    content: url("background-xl-services.7929f0a5.jpg");
  }

  #technologyBgImg {
    content: url("background-xl-technology.7cde8c19.jpg");
  }

  #procurementBgImg {
    content: url("background-xl-procurement.6de545e2.jpg");
  }
}

@media screen and (min-width: 1400px) {
  #indexBgImg {
    content: url("background-xxl.7837de77.jpg");
  }

  #aboutBgImg {
    content: url("background-xxl-about.d9782b69.jpg");
  }

  #servicesBgImg {
    content: url("background-xxl-services.7aadc77f.jpg");
  }

  #technologyBgImg {
    content: url("background-xxl-technology.1f00dc83.jpg");
  }

  #procurementBgImg {
    content: url("background-xxl-procurement.25a50222.jpg");
  }
}

nav {
  transition: background-color .25s;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler-icon {
  filter: var(--filter-secondary);
  background-image: url("bars-solid.30fa317e.svg");
}

.navbar-toggler-icon:hover {
  filter: var(--filter-primary);
}

.nav-link {
  color: var(--color-secondary);
  position: relative;
}

.nav-link:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--color-secondary);
  transform-origin: 100% 100%;
  transition: transform .25s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.active, .nav-link:hover {
  color: var(--color-primary);
}

.nav-link:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.show {
  background-color: var(--color-background);
}

#enquireBtn {
  background-color: var(--color-primary);
  color: var(--color-text);
  box-shadow: 1px 1px 2px var(--color-dark);
}

#enquireBtn:hover {
  background-color: var(--color-secondary);
  color: var(--color-dark);
}

main, footer {
  max-width: 1920px;
  background-color: var(--color-background);
}

section {
  max-width: 1440px;
}

.content-section {
  background-color: var(--color-background);
  border: 2px solid var(--color-border);
}

.content-section h2 {
  color: var(--color-secondary);
}

.content-section img {
  width: 100%;
}

#about img {
  content: url("about-1322x851.bce3fdfc.jpg");
}

#services img {
  content: url("services-1322x851.9fab3f75.jpg");
}

#technology img {
  content: url("technology-1322x851.3ed1fe90.jpg");
}

#procurement img {
  content: url("procurement-1322x851.1caf1ea4.jpg");
}

@media screen and (min-width: 576px) {
  .content-section p, .content-section li {
    text-align: justify;
  }
}

@media screen and (min-width: 992px) {
  #about img {
    content: url("about-480x720.8f02b0da.jpg");
  }

  #services img {
    content: url("services-480x720.ae921f68.jpg");
  }

  #technology img {
    content: url("technology-480x720.aca93337.jpg");
  }

  #procurement img {
    content: url("procurement-480x720.5dc39d8b.jpg");
  }
}

footer a {
  text-decoration: none;
}

.section-btn > a, #formSubmitBtn {
  background-color: var(--color-primary);
  color: var(--color-text);
}

.section-btn > a:hover, #formSubmitBtn:hover {
  background-color: var(--color-secondary);
  color: var(--color-dark);
}

#contact {
  color: var(--color-text);
}

#contact h2 {
  color: var(--color-secondary);
}

#contact h4 {
  color: var(--color-primary);
}

#contact img {
  width: 2rem;
  filter: var(--filter-secondary);
}

#contact a {
  color: var(--color-secondary);
}

#contact a:hover {
  color: var(--color-primary);
}

#email a {
  color: var(--color-text);
}

#contact form label, #contact form input {
  color: var(--color-secondary);
  caret-color: var(--color-secondary);
  background: none;
  border: none;
}

#contact form input {
  color: var(--color-primary);
}

#contact form select {
  color: var(--color-secondary);
  background-color: var(--color-background);
  border-color: var(--color-border);
}

#contact form select:hover {
  background-color: var(--color-secondary);
  color: var(--color-dark);
}

.form-border {
  border-bottom: 1px solid var(--color-border);
}

#copyright {
  color: var(--color-text);
}

#pageNavBtn {
  top: 1rem;
  position: -webkit-sticky !important;
  position: sticky !important;
}

#pageNavBtn a {
  filter: var(--filter-secondary);
  margin: 0;
  padding: .5rem 0;
  text-decoration: none;
}

#pageNavBtn a:hover {
  filter: var(--filter-primary);
}

#pageNavBtn img {
  width: 100%;
  height: 1.5rem;
}

.page-section {
  background-color: var(--color-background);
}

@media screen and (min-width: 576px) {
  .page-section {
    text-align: justify;
  }
}

@media screen and (min-width: 768px) {
  .page-section {
    width: 80%;
    border: 1px solid var(--color-border);
  }
}

.page-section h1 {
  color: var(--color-primary);
}

/*# sourceMappingURL=index.cd6fe644.css.map */
